.subsection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.item {
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.imageContainer {
  width: 576px;
  height: 344px;
}

.img {
  width: 100%;
  height: 100%;
}

.content {
  width: 40%;
}
#infotext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #494949;
  text-align: justify;
}
.informationtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 32px;
  /* identical to box height, or 114% */
  text-align: center;

  color: #000000;
}

@media (max-width: 981px) {
  .item {
    flex-direction: column;
    align-items: center;
  }

  .imageContainer {
    width: 100%;
    height: 100%;
  }

  .img {
    height: auto;
  }

  .content {
    width: 100%;
  }
}
