.services-box {
  border: 1px solid #ded9d9;
  padding: 28px 20px;
  border-radius: 24px;
  margin-bottom: 12px;
  max-height: 100px;
  overflow: hidden;
  cursor: pointer;
  transition: max-height 0.5s;
}
.services-header {
  display: flex;
  align-items: center;
}
.services-icon {
  background: #000;
  border-radius: 50%;
  padding: 6px;
  width: 45px;
  height: 45px;
  outline: 7px solid #00000014;
}
.services-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 30.24px;
  letter-spacing: -0.3px;
}
.services-description {
  height: fit-content;
}
.services-description p {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-top: 20px;
}

.services-box:hover {
  max-height: 500px;
  background: #ff9d000f;
}

.services-box:nth-child(odd):hover .services-icon {
  background: #ff9d00;
  outline: 7px solid #ff9d0029;
}
.services-box:nth-child(odd):hover .services-title {
  color: #ff9d00;
}
.services-box:nth-child(odd):hover .action-button {
  background: #ff9d00;
}

.services-box:nth-child(even):hover {
  background: #abc2703d;
}
.services-box:nth-child(even):hover .services-icon {
  background: #a5d726;
  outline: 7px solid #abc27033;
}
.services-box:nth-child(even):hover .services-title {
  color: #000;
}
.services-box:nth-child(even):hover .action-button {
  background: #a5d726;
}

.services-box:hover .services-icon {
  width: 45px;
  height: 45px;
}
.services-box:hover .services-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 50.4px;
  letter-spacing: -0.3px;
  margin-left: 0 !important;
  margin-top: 22px;
}

.services-box:hover .services-header {
  flex-direction: column;
  align-items: start;
}
