@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.plus-jakarta-sans {
  font-family: "Plus Jakarta Sans", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px !important;
  }
}
