.smallImage {
  position: absolute;
  top: 10%;
  right: 8%;
  width: 150px;
  height: 90px;
  z-index: 1;
}
.main-section-text {
  color: #5f6d7e;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.1px;
}
.home-section-side-image:after {
  position: absolute;
  content: "";
  width: 81%;
  display: block;
  height: 80%;
  background: linear-gradient(180deg, #ff9d00 0%, #f5cb86 69.5%, #ec9d1d 100%);
  top: 0;
  left: 0;
  border-radius: 30px;
  transform: rotate(339deg);
  margin-top: 12%;
}
.home-section-side-image:before {
  position: absolute;
  content: "";
  width: 81%;
  display: block;
  height: 80%;
  background: #ff9d002b;
  top: 0;
  border-radius: 30px;
  transform: rotate(339deg);
  margin-top: 3%;
  left: 3%;
}
.herocontainer {
  background-color: transparent;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.gradient-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;

  background: hsla(0, 0%, 100%, 1);
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 1) 35%,
    hsla(169, 45%, 53%, 0.25) 100%
  );
  background: -moz-linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 1) 35%,
    hsla(169, 45%, 53%, 0.25) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 1) 35%,
    hsla(169, 45%, 53%, 0.25) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#FFFFFF", endColorstr="#52BDAA", GradientType=1 );
}

.heroitem {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 80%;
  padding-left: 20px;
  padding-right: 20px;
}

.heroimageContainer {
  background-image: url("/public/images/hero.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 818.06px;
  height: 450.45px;
}

.herocontent {
  width: 40%;
}

.herocontenth1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 50px;
  display: flex;
  align-items: center;
  color: #000033;
}

#herotext {
  text-align: justify;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  /* or 162% */

  display: flex;
  align-items: center;

  color: rgba(0, 0, 51, 0.95);
}

.app-buttons {
  display: flex;
  justify-content: center;
}

.play-store-button,
.app-store-button {
  display: inline-block;
  margin: 10px;
}

.play-store-button img,
.app-store-button img {
  width: 10rem;
  height: auto;
}
@media (max-width: 981px) {
  .heroitem {
    flex-direction: column;
    align-items: center;
  }

  .heroimageContainer {
    width: 100%;
    margin-bottom: 20px;
  }

  .heroimg {
    height: auto;
  }

  .herocontent {
    width: 100%;
  }
  .herocontenth1 {
    text-align: center;
    line-height: 40px;
  }
}
.top-right-div {
  position: relative;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 15%;
  height: 11%;
  top: 20%;
  right: 5%;
  display: flex;
  float: right;
  background: #ffffff;
  box-shadow: 0px 8.68644px 21.7161px rgba(2, 147, 52, 0.1);
  border-radius: 15.346px;
  justify-content: center;
}
.percentageimage {
  width: 80%;
}
/* Add styles for the top right div */

.bottom-left-div {
  position: relative;

  width: 25%;
  height: 8%;
  top: 75%;
  left: 6%;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  justify-content: space-evenly;
  align-items: center;
  /* Add styles for the bottom left div */
}
.organizetext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;

  color: #191a15;
}
@media (max-width: 494px) {
  .heroimageContainer {
    background-image: url("/public/images/hero.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 350.06px;
  }
}
@media (max-width: 550px) {
  .top-right-div {
    position: relative;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 40%;
    height: 11%;
    top: 20%;
    right: 5%;
    display: flex;
    float: right;
    background: #ffffff;
    box-shadow: 0px 8.68644px 21.7161px rgba(2, 147, 52, 0.1);
    border-radius: 15.346px;
    justify-content: center;
  }
  .percentageimage {
    width: 60%;
  }
}
@media (max-width: 950px) {
  .bottom-left-div {
    position: relative;

    width: 25%;
    height: 8%;
    top: 70%;
    left: 6%;
    display: flex;
    background: #ffffff;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    justify-content: space-evenly;
    align-items: center;
    /* Add styles for the bottom left div */
  }
}
@media (max-width: 500px) {
  .bottom-left-div {
    position: relative;

    width: 35%;
    height: 6%;
    top: 65%;
    left: 6%;
    display: flex;
    background: #ffffff;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    justify-content: space-evenly;
    align-items: center;
    /* Add styles for the bottom left div */
  }
  .organizetext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;

    color: #191a15;
  }
}
